// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-categorized-list-jsx": () => import("./../src/templates/CategorizedList.jsx" /* webpackChunkName: "component---src-templates-categorized-list-jsx" */),
  "component---src-templates-list-jsx": () => import("./../src/templates/List.jsx" /* webpackChunkName: "component---src-templates-list-jsx" */),
  "component---src-templates-portfolio-jsx": () => import("./../src/templates/Portfolio.jsx" /* webpackChunkName: "component---src-templates-portfolio-jsx" */),
  "component---src-templates-portfolios-jsx": () => import("./../src/templates/Portfolios.jsx" /* webpackChunkName: "component---src-templates-portfolios-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-resume-jsx": () => import("./../src/templates/Resume.jsx" /* webpackChunkName: "component---src-templates-resume-jsx" */),
  "component---src-templates-tagged-list-jsx": () => import("./../src/templates/TaggedList.jsx" /* webpackChunkName: "component---src-templates-tagged-list-jsx" */)
}

